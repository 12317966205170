<template>
  <div>
    <the-header />
    <div>
      <div>
        <h1>
          {{ $t('main_message') }}
        </h1>
        <span>
          {{ $t('line_1') }}
        </span>
        <span>
          {{ $t('line_2') }}
        </span>
        <span>
          {{ $t('line_3') }}
        </span>
        <button
          id="btn-home"
          @click="() => this.$router.push({ name: 'Home' })"
        >
          {{ $t('go_to') }}
        </button>
      </div>
    </div>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from '../layout/TheHeader'
import TheFooter from '../layout/TheFooter'

/**
 * Component to represent the page not found.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:20 p. m.
 */
export default {
  name: 'PageNotFound',
  components: {
    TheHeader,
    TheFooter
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
</style>

<i18n>
  {
  "es": {
  "go_to": "Ir a inicio",
  "line_1": "Página no encontrada",
  "line_2": "Lo sentimos, la página que buscas no",
  "line_3": "Existe o no se puede encontrar.",
  "main_message": "404!!!"
  }
  }
</i18n>
